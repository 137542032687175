@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.onboarding-profile {
    font-family: "Varela Round", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f8f8f8;
    padding: 2rem;
}

.logo {
    font-size: 2.5rem;
    color: #7e57c2;
    font-weight: bold;
    margin-bottom: 1rem;
}

.title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
}

.calendar-container {
    width: 100%;
    max-width: 800px;
    margin-bottom: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    min-height: 950px;
}

.next-button {
    background-color: #7e57c2;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
}

.next-button:hover {
    background-color: #6a4caf;
}

/* You may need to add additional styles to customize the appearance of the MyCalendar component */

.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 0px;
    top: 0;
}

.logo-link {
    color: #7e57c2;
    font-weight: bold;
    text-decoration: none;
}
