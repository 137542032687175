@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.onboarding-profile {
    background-image: url("./background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: "Varela Round", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 2rem;
}

.logo {
    font-size: 2.5rem;
    color: #7e57c2;
    font-weight: bold;
    margin-bottom: 2rem;
}

form {
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 1.5rem;
}

label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

input {
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.next-button {
    background-color: #7e57c2;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.next-button:hover {
    background-color: #6a4caf;
}
