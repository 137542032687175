@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.onboarding-profile {
    font-family: "Varela Round", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 2rem;
    text-align: center;
}

.logo {
    font-size: 2.5rem;
    color: #7e57c2;
    font-weight: bold;
    margin-bottom: 4rem;
}

.thank-you-content {
    max-width: 600px;
    width: 100%;
}

.thank-you-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
}

.thank-you-message {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.5;
}

.next-button {
    text-decoration: none;
}
