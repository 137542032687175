@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.onboarding-profile {
    background-image: url("./background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: "Varela Round", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 2rem;
}

.logo {
    font-size: 2.5rem;
    color: #7e57c2;
    font-weight: bold;
    margin-bottom: 2rem;
}

form {
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 1.5rem;
}

label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.interests-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1rem;
}

.interest-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.interest-item.selected {
    background-color: #7e57c2;
    color: white;
    border-color: #7e57c2;
}

.interest-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.interest-name {
    font-size: 0.9rem;
    text-align: center;
}

.next-button {
    background-color: #7e57c2;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.next-button:hover {
    background-color: #6a4caf;
}

.add-button {
    background-color: white;
    color: #6a4caf;
    border: none;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 30%;
    hover: #6a4caf;
    effect: ease;
}
